var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('BCard', {
    attrs: {
      "body-class": "d-flex-center gap-3"
    }
  }, [_c('div', [_c('div', {
    staticClass: "d-flex gap-3"
  }, [_c('BButton', {
    attrs: {
      "variant": "primary",
      "pills": ""
    },
    on: {
      "click": _vm.onClickQrScanHandle
    }
  }, [_vm._v(" Test QR Scan ")]), _vm.result ? _c('div', {
    staticClass: "d-flex-center"
  }, [_vm._v(" Kết quả: "), _c('strong', [_vm._v(_vm._s(_vm.result))])]) : _vm._e()], 1), _c('IAmQrCodeScan', {
    attrs: {
      "idProp": "icon-list-test-qr-scan"
    },
    on: {
      "apply": _vm.onApplyQrScanHandle
    }
  })], 1), _c('div', [_c('div', {
    staticClass: "d-flex gap-3"
  }, [_c('BButton', {
    attrs: {
      "variant": "primary",
      "pills": ""
    },
    on: {
      "click": _vm.onClickQrScanByUploadHandle
    }
  }, [_vm._v(" Test QR Scan By Upload ")]), _vm.resultByUpload ? _c('div', {
    staticClass: "d-flex-center"
  }, [_vm._v(" Kết quả: "), _c('strong', [_vm._v(_vm._s(_vm.resultByUpload))])]) : _vm._e()], 1), _c('IAmQrCodeScanByUpload', {
    attrs: {
      "idProp": "icon-list-test-qr-scan-by-upload"
    },
    on: {
      "apply": _vm.onApplyQrScanByUploadHandle
    }
  })], 1)]), _c('BCard', [_c('BRow', _vm._l(_vm.iconsList, function (icon, index) {
    return _c('BCol', {
      key: index,
      staticClass: "d-flex gap-1",
      attrs: {
        "cols": "2"
      }
    }, [_c('IAmIcon', {
      attrs: {
        "icon": icon,
        "size": "30",
        "color": _vm.getRandomColor()
      }
    }), _vm._v(" : "), _c('span', {
      staticClass: "font-medium-2"
    }, [_vm._v(_vm._s(icon))])], 1);
  }), 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }