<template lang="">
  <div>
    <BCard body-class="d-flex-center gap-3">
      <div>
        <div class="d-flex gap-3">
          <BButton
            variant="primary"
            pills
            @click="onClickQrScanHandle"
          >
            Test QR Scan
          </BButton>
          <div
            v-if="result"
            class="d-flex-center"
          >
            Kết quả: <strong>{{ result }}</strong>
          </div>
        </div>
        <IAmQrCodeScan
          idProp="icon-list-test-qr-scan"
          @apply="onApplyQrScanHandle"
        />
      </div>

      <div>
        <div class="d-flex gap-3">
          <BButton
            variant="primary"
            pills
            @click="onClickQrScanByUploadHandle"
          >
            Test QR Scan By Upload
          </BButton>
          <div
            v-if="resultByUpload"
            class="d-flex-center"
          >
            Kết quả: <strong>{{ resultByUpload }}</strong>
          </div>
        </div>
        <IAmQrCodeScanByUpload
          idProp="icon-list-test-qr-scan-by-upload"
          @apply="onApplyQrScanByUploadHandle"
        />
      </div>
    </BCard>
    <BCard>
      <BRow>
        <BCol
          v-for="(icon, index) in iconsList"
          :key="index"
          cols="2"
          class="d-flex gap-1"
        >
          <IAmIcon
            :icon="icon"
            size="30"
            :color="getRandomColor()"
          />
          :
          <span class="font-medium-2">{{ icon }}</span>
        </BCol>
      </BRow>
    </BCard>
  </div>
</template>
<script>
import {
  BCard, BRow, BCol, BButton,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'

import icons from '@icons'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    IAmQrCodeScan: () => import('@/components/IAmQrCodeScan.vue'),
    IAmQrCodeScanByUpload: () => import('@/components/IAmQrCodeScanByUpload.vue'),
  },
  setup() {
    function getRandomColor() {
      const letters = '0123456789ABCDEF'
      let color = '#'
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)]
      }
      return color
    }
    const iconsList = Object.keys(icons)

    function onClickQrScanHandle() {
      this.$bvModal.show('icon-list-test-qr-scan')
    }

    function onClickQrScanByUploadHandle() {
      this.$bvModal.show('icon-list-test-qr-scan-by-upload')
    }

    const result = ref(null)
    function onApplyQrScanHandle(value) {
      result.value = value
    }

    const resultByUpload = ref(null)
    function onApplyQrScanByUploadHandle(value) {
      resultByUpload.value = value
    }
    return {
      iconsList,
      getRandomColor,
      onClickQrScanHandle,
      onApplyQrScanHandle,
      result,
      onClickQrScanByUploadHandle,
      onApplyQrScanByUploadHandle,
      resultByUpload,
    }
  },
}
</script>
<style lang="">

</style>
